import React, {
  createContext,
  HTMLAttributes,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'

import { addAlphaToColor, dashedLine } from 'common/utils/style'

type ActivePosition = {
  left: number
  top: number
  width: number
  height: number
}

const TabsContext = createContext<null | {
  setActivePosition: (pos: ActivePosition) => void
}>(null)

type Props = {
  children: ReactNode
  variant?: 'horizontal' | 'vertical'
}

export const Tabs = ({ variant = 'horizontal', children }: Props) => {
  const [activePosition, setActivePosition] = useState<null | ActivePosition>(
    null
  )

  return (
    <TabsContext.Provider value={{ setActivePosition }}>
      <StyledTabs variant={variant}>
        {children}
        {activePosition !== null && (
          <ActiveElement
            style={
              variant === 'vertical'
                ? {
                    top: `${activePosition.top}px`,
                    height: `${activePosition.height}px`,
                  }
                : {
                    left: `${activePosition.left}px`,
                    width: `${activePosition.width}px`,
                  }
            }
            variant={variant}
          />
        )}
        {variant === 'horizontal' && <Border />}
      </StyledTabs>
    </TabsContext.Provider>
  )
}

type TabProps = HTMLAttributes<HTMLButtonElement> & {
  isActive: boolean
  variant?: 'horizontal' | 'vertical'
}

export const Tab: React.FC<TabProps> = ({
  isActive,
  variant = 'horizontal',
  ...props
}) => {
  const ref = useRef<HTMLButtonElement | null>(null)
  const tabContext = useContext(TabsContext)
  const { setActivePosition } = tabContext || {}

  if (!tabContext) {
    throw new Error('The Tab component needs to be inside tabs component')
  }

  useEffect(() => {
    function updatePosition() {
      if (isActive && ref.current) {
        setActivePosition?.({
          left: ref.current.offsetLeft,
          width: ref.current.offsetWidth,
          top: ref.current.offsetTop,
          height: ref.current.offsetHeight,
        })
      }
    }

    updatePosition()

    window.addEventListener('resize', updatePosition)

    return () => {
      window.removeEventListener('resize', updatePosition)
    }
  }, [isActive, setActivePosition])

  return (
    <StyledTab ref={ref} isActive={isActive} variant={variant} {...props} />
  )
}

const StyledTabs = styled.div<{ variant: Props['variant'] }>`
  display: flex;
  flex-direction: ${({ variant }) =>
    variant === 'vertical' ? 'column' : 'row'};
  align-items: ${({ variant }) =>
    variant === 'vertical' ? 'flex-start' : 'center'};
  position: relative;
  width: ${({ variant }) => (variant === 'vertical' ? '17.75rem' : '100%')};
  height: fit-content;
  overflow: auto;
  border-bottom: ${({ variant, theme }) =>
    variant === 'vertical'
      ? 'unset'
      : `1px dashed
    ${addAlphaToColor(theme.colors.foreground.default, 60)}`};

  ${({ theme, variant }) =>
    variant === 'vertical' &&
    dashedLine('left', addAlphaToColor(theme.colors.foreground.subtle, 60))};

  ${({ theme }) => theme.media.md} {
    border-bottom: 0px;
  }
`

const Border = styled.div`
  ${({ theme }) => theme.media.md} {
    position: absolute;
    bottom: 0px;
    height: 0.0625rem;
    width: 100%;
    ${({ theme }) =>
      dashedLine(
        'bottom',
        addAlphaToColor(theme.colors.foreground.subtle, 60)
      )};
  }
`

const ActiveElement = styled.div<{ variant: Props['variant'] }>`
  position: absolute;
  bottom: 0px;
  left: ${({ variant }) => variant === 'vertical' && '0px'};
  width: ${({ variant }) => variant === 'vertical' && '2px'};
  height: ${({ variant }) => variant === 'horizontal' && '1px'};
  background: ${({ theme }) => theme.colors.foreground.default};
  transition: all ${({ theme }) => theme.transitions.ease('200ms')};

  border-radius: 999px;
`

const StyledTab = styled.button<{
  isActive?: boolean
  variant: Props['variant']
}>`
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  text-align: left;
  white-space: ${({ variant }) => variant === 'horizontal' && 'nowrap'};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 1rem 0.625rem;
  transition: color ${({ theme }) => theme.transitions.ease('200ms')};

  :first-child {
    padding-left: ${({ variant }) => variant === 'horizontal' && '1rem'};
  }

  :last-of-type {
    padding-right: ${({ variant }) => variant === 'horizontal' && '1rem'};
  }

  @media (hover: hover) {
    &:hover {
      color: ${({ theme }) => theme.colors.foreground.default};
    }
  }

  color: ${({ theme, isActive }) =>
    `${addAlphaToColor(theme.colors.foreground.default, isActive ? 100 : 80)}`};
  font-weight: ${({ isActive }) => `${isActive ? 400 : 300}`};

  ${({ theme }) => theme.media.md} {
    padding: ${({ variant }) =>
      variant === 'horizontal' ? '1.375rem 1.25rem' : '0.625rem 1.25rem'};

    /* :first-child {
      padding: 1.375rem 1.25rem 1.375rem 0rem;
    }

    :last-of-type {
      padding: 1.375rem 0rem 1.375rem 1.25rem;
    } */
  }
`

import styled, { CSSProp } from 'styled-components'

import { MyTheme } from '../../../@types/custom'

export const icons = {
  'arrow-left': require('./icons/arrow-left.svg'),
  'arrow-right': require('./icons/arrow-right.svg'),
  'book-appointment': require('./icons/book-appointment.svg'),
  'box-selected': require('./icons/box-selected.svg'),
  'caret-down': require('./icons/caret-down.svg'),
  'caret-up': require('./icons/caret-up.svg'),
  'closure-covid': require('./icons/closure-covid.svg'),
  'double-arrow-left': require('./icons/double-arrow-left.svg'),
  'double-arrow-right': require('./icons/double-arrow-right.svg'),
  'hours-holidays': require('./icons/hours-holidays.svg'),
  'learn-more': require('./icons/learn-more.svg'),
  'map-pointer': require('./icons/map-pointer.svg'),
  'message-bubble': require('./icons/message-bubble.svg'),
  'usp-award': require('./icons/usp-award.svg'),
  'usp-baby': require('./icons/usp-baby.svg'),
  'usp-book': require('./icons/usp-book.svg'),
  'usp-genetic': require('./icons/usp-genetic.svg'),
  'usp-heart': require('./icons/usp-heart.svg'),
  'usp-house': require('./icons/usp-house.svg'),
  'usp-imaging': require('./icons/usp-imaging.svg'),
  'usp-location': require('./icons/usp-location.svg'),
  'usp-puzzle': require('./icons/usp-puzzle.svg'),
  'usp-rates': require('./icons/usp-rates.svg'),
  'usp-science': require('./icons/usp-science.svg'),
  'usp-star': require('./icons/usp-star.svg'),
  'usp-support': require('./icons/usp-support.svg'),
  'usp-treatments': require('./icons/usp-treatments.svg'),
  'usp-university': require('./icons/usp-university.svg'),
  'x-circle': require('./icons/x-circle.svg'),
  apple: require('./icons/apple.svg'),
  book: require('./icons/book.svg'),
  booking: require('./icons/booking.svg'),
  box: require('./icons/box.svg'),
  calendar: require('./icons/calendar.svg'),
  checkmark: require('./icons/checkmark.svg'),
  clinics: require('./icons/clinics.svg'),
  clock: require('./icons/clock.svg'),
  close: require('./icons/close.svg'),
  cookies: require('./icons/cookies.svg'),
  diagnosis: require('./icons/diagnosis.svg'),
  directions: require('./icons/directions.svg'),
  dots: require('./icons/dots.svg'),
  download: require('./icons/download.svg'),
  events: require('./icons/events.svg'),
  expand: require('./icons/expand.svg'),
  external: require('./icons/external.svg'),
  face: require('./icons/face.svg'),
  facebook: require('./icons/facebook.svg'),
  filters: require('./icons/filters.svg'),
  hamburguer: require('./icons/hamburguer.svg'),
  house: require('./icons/house.svg'),
  info: require('./icons/info.svg'),
  less: require('./icons/less.svg'),
  link: require('./icons/link.svg'),
  loading: require('./icons/loading.svg'),
  mail: require('./icons/mail.svg'),
  maps: require('./icons/maps.svg'),
  news: require('./icons/news.svg'),
  phone: require('./icons/phone.svg'),
  play: require('./icons/play.svg'),
  plus: require('./icons/plus.svg'),
  search: require('./icons/magnifier.svg'),
  support: require('./icons/support.svg'),
  treatment: require('./icons/treatment.svg'),
  treatments: require('./icons/treatments.svg'),
  twitter: require('./icons/twitter.svg'),
  user: require('./icons/user.svg'),
  world: require('./icons/world.svg'),
}

export type IconOptions = keyof typeof icons

export function isValidIcon(iconName: unknown): iconName is IconOptions {
  if (typeof iconName !== 'string' || !iconName) return false
  return iconName in icons
}

export const getValidIcon = (icon: unknown): IconOptions | undefined => {
  if (isValidIcon(icon)) {
    return icon
  } else return
}

export type IconProps = {
  icon: IconOptions
  size?: number
  styles?: CSSProp<MyTheme> | undefined
}

export const Icon = ({ icon, size = 16, styles }: IconProps) => {
  const IconComponent = icons[icon].default

  if (!IconComponent) {
    console.warn(`Icon "${icon}" does not exist in icons.`)
    return null
  }

  return (
    <IconWrapper size={size} styles={styles}>
      <IconComponent />
    </IconWrapper>
  )
}

const IconWrapper = styled.div<{
  size: number | undefined
  styles: CSSProp<MyTheme> | undefined
}>`
  display: inline-flex;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  color: ${({ color }) => color || 'currentColor'};

  ${({ styles }) => styles && styles}
`

import NextLink from 'next/link'
import { useRouter } from 'next/router'
import styled from 'styled-components'

import { Container, Text, Link, Button, Theme, Icon } from 'common/UI'
import { DYNAMIC_PHONE_NUMBER_CLASSNAME } from 'common/constants'
import { TextStoryblok } from 'common/types'
import { hasValidLink } from 'common/utils/content'
import { GTMEvents, trackEvent } from 'common/utils/gtm'
import { addAlphaToColor, dashedLine } from 'common/utils/style'
import { useDataContext } from 'lib/dataContext'
import { useTranslation } from 'lib/i18n'
import { CMSLink } from 'modules/shared'

type Props = {
  showRegionModal: () => void
}

export const Topbar = ({ showRegionModal }: Props): JSX.Element => {
  const {
    query: { lang },
  } = useRouter()
  const { i18n } = useTranslation()
  const { config, regions, isSubSite } = useDataContext()
  const phoneNumber = config?.content.phone_number
  const contactLink = config?.content.contact_link
  const portalUrl = config?.content.portal_link
  const extraPhoneNumbersLabel = config?.content.extra_numbers_label
  const extraPhoneNumbers = config?.content.extra_numbers
  const currRegion = regions?.find((region) => region.code === lang)
  const hasContactInfo = phoneNumber || contactLink

  return (
    <Theme colorTheme={isSubSite ? 'dark-purple' : 'dark-blue'}>
      <Wrapper>
        <Holder>
          {hasContactInfo || isSubSite ? (
            <InfoHolder>
              {isSubSite && (
                <NextLink href={`/${lang}`} passHref legacyBehavior>
                  <BackToRegion>
                    <Icon
                      icon="arrow-left"
                      styles={{ marginRight: '0.5rem' }}
                    />
                    TFP {(currRegion?.name || '').replace(/\s\(.+/g, '')}
                  </BackToRegion>
                </NextLink>
              )}
              {hasContactInfo && (
                <>
                  <Questions
                    as="span"
                    variant="fourteen"
                    color="palette.beige.60"
                    css={{ marginRight: '0.5rem' }}
                  >
                    {i18n('questions')}
                  </Questions>
                  {phoneNumber && (
                    <Link
                      className={
                        config?.content.isDynamic
                          ? DYNAMIC_PHONE_NUMBER_CLASSNAME
                          : ''
                      }
                      onClick={() => {
                        trackEvent(GTMEvents.Call, {
                          'tfp-phone-number': phoneNumber,
                        })
                      }}
                      href={`tel:${phoneNumber
                        .replace('+', '00')
                        .replace(/\D+/g, '')}`}
                    >
                      {phoneNumber}
                    </Link>
                  )}
                  {extraPhoneNumbers && (
                    <>
                      {extraPhoneNumbers.map((number: TextStoryblok) => {
                        if (!number.content) return null
                        return (
                          <>
                            <Questions
                              as="span"
                              variant="fourteen"
                              color="palette.beige.60"
                              css={{ marginLeft: '0.5rem' }}
                            >
                              {extraPhoneNumbersLabel}
                            </Questions>
                            <Link
                              key={number.content}
                              onClick={() => {
                                trackEvent(GTMEvents.Call, {
                                  'tfp-phone-number': number.content,
                                })
                              }}
                              href={`tel:${number.content
                                .replace('+', '00')
                                .replace(/\D+/g, '')}`}
                              style={{ margin: '0 0.5rem' }}
                            >
                              {number.content}
                            </Link>
                          </>
                        )
                      })}
                    </>
                  )}
                  {phoneNumber && hasValidLink(contactLink) && (
                    <Text
                      as="span"
                      variant="fourteen"
                      color="palette.beige.60"
                      css={{ margin: '0 0.25rem' }}
                    >
                      {i18n('or')}
                    </Text>
                  )}
                  {contactLink && hasValidLink(contactLink) && (
                    <CMSLink linkStyle="text" href={contactLink}>
                      {i18n('messageUs')}
                    </CMSLink>
                  )}
                </>
              )}
              {/* spacer on small mobile with overflow scroll */}
              <div style={{ width: '1rem', height: '1px', flexShrink: 0 }} />
            </InfoHolder>
          ) : (
            // Empty div so the rest of items align to the end
            <div />
          )}
          <LangAndPatientHolder>
            {currRegion && (
              <LangSwitcherHolder>
                <Button
                  size="micro"
                  variant="text"
                  leftIcon="world"
                  rightIcon="caret-down"
                  onClick={() => showRegionModal()}
                >
                  {currRegion.name}
                </Button>
              </LangSwitcherHolder>
            )}
            {portalUrl && (
              <PatientPortalHolder>
                <Link
                  href={portalUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  leftIcon="calendar"
                  rightIcon="arrow-right"
                  onClick={() => {
                    trackEvent(GTMEvents.PortalClick, {
                      element: 'TopBar',
                    })
                  }}
                >
                  {i18n('patientPortal')}
                </Link>
              </PatientPortalHolder>
            )}
          </LangAndPatientHolder>
        </Holder>
      </Wrapper>
    </Theme>
  )
}

const Wrapper = styled.header`
  background-color: ${({ theme }) => theme.colors.background.default};
  color: ${({ theme }) => theme.colors.foreground.default};

  font-size: 0.875rem;
  line-height: 1;
`

const Questions = styled(Text)`
  display: none;

  ${({ theme }) => theme.media.sm} {
    display: inline;
  }
`

const Holder = styled(Container)`
  padding: 0;

  overflow: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;

  ${({ theme }) => theme.media.md} {
    flex-direction: row;
  }
`

const BackToRegion = styled(Link)`
  display: flex;
  align-items: center;

  padding-right: 0.5rem;
  margin-right: 0.5rem;
  border-right: 1px solid
    ${({ theme }) => addAlphaToColor(theme.colors.foreground.default, 20)};
`

const Info = styled.div`
  padding: 0.4rem 1rem;
  min-height: 2rem;
  white-space: nowrap;

  display: flex;
  align-items: center;
`

const InfoHolder = styled(Info)`
  display: flex;
  align-items: center;
`

const LangAndPatientHolder = styled.div`
  ${({ theme }) => theme.media.sm} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ${({ theme }) => theme.media.md} {
    border-top: none;
  }
`

const BorderHolder = styled(Info)`
  ${({ theme }) => theme.media.sm} {
    flex: 1;

    &:not(:first-of-type) {
      ${({ theme }) =>
        dashedLine(
          'left',
          addAlphaToColor(theme.colors.foreground.default, 20)
        )};
      padding-left: 1rem;
      margin-left: 1rem;
    }
  }
`

const LangSwitcherHolder = styled(BorderHolder)`
  display: none;

  ${({ theme }) => theme.media.sm} {
    display: block;
  }
`

const PatientPortalHolder = styled(BorderHolder)`
  ${({ theme }) =>
    dashedLine('top', addAlphaToColor(theme.colors.foreground.default, 20))};

  ${({ theme }) => theme.media.sm} {
    border-top: none;
  }
`

import React, { InputHTMLAttributes } from 'react'

import { Icon } from '../UI/Icon'
import { TextField } from './TextField'

type Props = InputHTMLAttributes<HTMLInputElement>

export const SearchInput = (props: Props): JSX.Element => {
  return (
    <div
      css={{
        position: 'relative',
        flex: 1,
      }}
    >
      <TextField
        variant="input"
        name="search"
        {...props}
        style={{ paddingLeft: '3rem', ...props.style }}
      />

      <Icon
        icon="search"
        styles={{
          position: 'absolute',
          left: '1rem',
          top: '50%',
          transform: 'translateY(-50%)',
          pointerEvents: 'none',
        }}
      />
    </div>
  )
}

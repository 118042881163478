import {
  AnchorHTMLAttributes,
  forwardRef,
  Ref,
  useEffect,
  useState,
} from 'react'
import styled from 'styled-components'

import { IconHolder } from '../UI/IconHolder'
import { ButtonBaseProps, StyledButton } from './Button'
import { Icon } from './Icon'

export type ButtonLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> &
  Omit<ButtonBaseProps, 'isLoading'>

export const ButtonLink = forwardRef(function ButtonLink(
  {
    variant = 'solid',
    size = 'default',
    isFull = false,
    leftIcon,
    rightIcon,
    children,
    ...props
  }: ButtonLinkProps,
  ref: Ref<HTMLAnchorElement>
) {
  // Prevents hydration error
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return null
  }

  return (
    <StyledButton
      as="a"
      isFull={isFull}
      size={size}
      variant={variant}
      ref={ref}
      {...props}
      css={{
        paddingLeft:
          leftIcon && size === 'small' && children
            ? '0.75rem'
            : leftIcon && size === 'default'
            ? '1rem'
            : '1.25rem',
        paddingRight:
          rightIcon && size === 'small' && children
            ? '0.75rem'
            : rightIcon && size === 'default'
            ? '1rem'
            : '1.25rem',
      }}
    >
      <Inner>
        {leftIcon && (
          <IconHolder
            size="1.25rem"
            css={{
              marginRight: children ? '0.5rem' : 0,
              opacity: leftIcon === 'external' ? 0.6 : 1,
            }}
          >
            <Icon icon={leftIcon} size={20} />
          </IconHolder>
        )}

        {children}

        {rightIcon && (
          <IconHolder
            size="1.25rem"
            css={{ marginLeft: children || leftIcon ? '0.5rem' : 0 }}
          >
            <Icon icon={rightIcon} size={20} />
          </IconHolder>
        )}
      </Inner>
    </StyledButton>
  )
})

const Inner = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

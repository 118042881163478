import NextImage from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { InputHTMLAttributes, useRef, useState } from 'react'
import styled from 'styled-components'

import useOutsideClick from 'common/hooks/useOutsideClick'
import { getImageAttributes } from 'common/utils/content'
import { removeLeadingAndTrailingSlash } from 'common/utils/string'
import { addAlphaToColor } from 'common/utils/style'
import { useTranslation } from 'lib/i18n'
import { convertDistance } from 'modules/Blocks/ClinicLocator/convertDistance'
import { GeolocationStatus } from 'modules/Blocks/ClinicLocator/types'
import { ContentLoader } from 'modules/shared'

import { Checkbox } from './Checkbox'
import { ErrorText } from './Field'
import { Icon } from './Icon'
import { Popover } from './Popover'
import { Text } from './Text'
import { TextField } from './TextField'

type Props = InputHTMLAttributes<HTMLInputElement> & {
  options: {
    id: string
    name: string
    image: string
    country: string | null
    distance: number | null
    url: string
  }[]
  disabledNearMe: boolean
  checkedNearMe: boolean
  onChangeNearMe: () => void
  geolocationStatusNearMe: GeolocationStatus
  isLoading: boolean
  hasError: boolean
}

export const SelectClinic = ({
  options,
  disabledNearMe,
  checkedNearMe,
  onChangeNearMe,
  geolocationStatusNearMe,
  isLoading,
  hasError,
  ...props
}: Props): JSX.Element => {
  const { i18n } = useTranslation()
  const {
    query: { lang },
  } = useRouter()
  const [, region] = ((lang as string) || '').split('-')
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null)
  useOutsideClick(wrapperRef, () => {
    setIsOpen(false)
  })

  const LOADER_AMOUNT = 6

  return (
    <Wrapper ref={wrapperRef}>
      <div
        ref={setReferenceElement}
        onClick={() => {
          setIsOpen(true)
        }}
      >
        <div
          css={{
            position: 'relative',
            flex: 1,
          }}
        >
          <TextField
            variant="input"
            name="search"
            autoComplete="off"
            {...props}
            style={{
              paddingLeft: '48px',
              borderRadius: '.75rem',
              ...props.style,
            }}
          />
          <Icon
            icon="search"
            styles={{
              position: 'absolute',
              left: '1rem',
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          />
        </div>
        {geolocationStatusNearMe !== GeolocationStatus.Unsupported && (
          <NearMe
            disabled={disabledNearMe}
            checked={checkedNearMe}
            label={i18n('clinicLocator.nearMe')}
            onChange={() => {
              setIsOpen(true)
              onChangeNearMe()
            }}
          />
        )}
      </div>
      <Popover
        referenceElement={referenceElement}
        isOpen={isOpen}
        placement="bottom-start"
        offset={[0, 0]}
      >
        <Dropdown>
          <HeaderWrapper>
            {geolocationStatusNearMe === GeolocationStatus.Error ? (
              <ErrorText as="div" variant="fourteen" css={{ display: 'block' }}>
                {i18n('clinicLocator.geolocationError')}
              </ErrorText>
            ) : isLoading ? (
              <Text as="p" variant="sixteen">
                {i18n('select-clinic-header.select-clinic-searching')}
              </Text>
            ) : hasError ? (
              <div css={{ display: 'flex' }}>
                <Text as="p" variant="sixteen">
                  {i18n('select-clinic-header.select-clinic-error')}
                </Text>
                <StyledTextError as="p" variant="sixteen">
                  {props.value}
                </StyledTextError>
              </div>
            ) : (
              <Text as="p" variant="sixteen">
                {i18n('select-clinic-header.select-clinic-description')}
              </Text>
            )}
          </HeaderWrapper>
          <ItemWrapper>
            {isLoading &&
              new Array(LOADER_AMOUNT).fill('').map((_, i) => (
                <ContentLoader
                  key={i}
                  viewBox="0 0 406 60"
                  preserveAspectRatio="none"
                  style={{
                    opacity: Math.min(
                      ((LOADER_AMOUNT - 1 - i) * (100 / LOADER_AMOUNT - 1) +
                        10) /
                        100,
                      1
                    ),
                  }}
                >
                  <rect x="8" y="8" rx="4" ry="4" width="44" height="44" />
                  <rect x="64" y="8" rx="4" ry="4" width="260" height="18" />
                  <rect x="64" y="30" rx="4" ry="4" width="225" height="18" />
                </ContentLoader>
              ))}
            {!isLoading &&
              options?.map((option, i) => {
                const url = option.url.startsWith('http')
                  ? option.url
                  : `/${removeLeadingAndTrailingSlash(option.url)}`
                return (
                  <Item href={url} key={`${option.name}-${i}`}>
                    <ImageHolder>
                      {option.image && (
                        <NextImage
                          src={
                            getImageAttributes(
                              { filename: option.image },
                              '400x400/smart/filters:format(webp)'
                            ).src
                          }
                          style={{ objectFit: 'cover', height: '100%' }}
                          alt={option.name}
                          fill
                        />
                      )}
                    </ImageHolder>
                    <TextWrapper>
                      <Text
                        as="p"
                        variant="sixteen/regular"
                        style={{ textAlign: 'left' }}
                      >
                        {option.name}
                      </Text>
                      <StyledDescription as="p" variant="sixteen">
                        {option.country}
                        {option.distance && (
                          <WrapperDistance>
                            <Dot /> {convertDistance(option.distance, region)}{' '}
                          </WrapperDistance>
                        )}
                      </StyledDescription>
                    </TextWrapper>
                  </Item>
                )
              })}
          </ItemWrapper>
          <StyledBottom
            alt="Bottom banner"
            css={{ display: 'block' }}
            src="/assets/banner.png"
          />
        </Dropdown>
      </Popover>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;

  ${({ theme }) => theme.media.md} {
    width: 25.875rem;
  }
`

const Dropdown = styled.div`
  overflow: hidden;
  margin: auto;
  z-index: ${({ theme }) => theme.zIndex.highest};
  width: 100%;
  border-radius: 16px;
  box-shadow: ${({ theme }) => theme.shadows.medium};
  background: ${({ theme }) => theme.colors.palette.white};

  ${({ theme }) => theme.media.md} {
    width: 25.875rem;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  padding: 0.75rem;
  border-bottom: 1px dashed
    ${({ theme }) => addAlphaToColor(theme.colors.foreground.default, 10)};
`

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0.25rem;
  max-height: 328px;
  overflow-y: auto;
`

const Item = styled(Link)`
  display: flex;
  align-items: start;
  padding: 8px 8px;
  border-radius: 12px;
  transition: background-color ${({ theme }) => theme.transitions.ease()};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.foreground.default};

  @media (hover: hover) {
    &:hover {
      background: ${({ theme }) =>
        addAlphaToColor(theme.colors.foreground.default, 5)};
    }
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.75rem;
  align-items: start;
`

const StyledDescription = styled(Text)`
  display: flex;
  color: ${({ theme }) => addAlphaToColor(theme.colors.foreground.default, 80)};
`

const WrapperDistance = styled.div`
  display: flex;
  align-items: center;
`

const Dot = styled.div`
  display: flex;
  width: 0.125rem;
  height: 0.125rem;
  background: ${({ theme }) =>
    addAlphaToColor(theme.colors.foreground.default, 80)};
  margin: 0px 0.25rem;
`

const StyledBottom = styled.img`
  object-fit: cover;
  height: 8px;
  width: 100%;
`

const NearMe = styled(Checkbox)`
  margin-top: 16px;
  display: flex;

  ${({ theme }) => theme.media.md} {
    margin-top: unset;

    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
`

const ImageHolder = styled.div`
  display: block;
  position: relative;
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 0.25rem;
  overflow: hidden;
  flex-shrink: 0;
  background-color: ${({ theme }) =>
    addAlphaToColor(theme.colors.foreground.default, 5)};
`

const StyledTextError = styled(Text)`
  color: ${({ theme }) => theme.colors.accent.subtle};
  margin-left: 0.25rem;
`
